.mainPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20vh 5vh 25vh;
    text-align: center;
    font-size: larger;
    
    .info {
        margin-bottom: 3rem;
    }
    
    .siteTitle {
        margin-bottom: 5rem;
    }
    
    .siteDesc {
        margin-bottom: 3rem;
    }
    
    .siteDiplomes {
        margin-bottom: 1rem;
    }
    overflow-x: hidden;
}

.refs {
    display: flex;
    width: 100%;
    margin: 0 0 25vh;
    
    a {
        text-decoration: none;
        text-decoration: none;
        color: $white;

        .eduRef, .traineauRef {
            position: relative;
            float: left;
            
            img {
                width: 100%;
                object-fit: cover;
            }
            
            &:hover {
                cursor: pointer;
            }
            
            .square {
                position: absolute;
                top:0;
                width: 100%;
                height: 100%;
                text-align: center;
                visibility: hidden;
                opacity: 0;
                background-color: rgba(#000000, 0.6);
                -webkit-transition: visibility 0.5s, opacity 0.5s linear; 
                transition: visibility 0.5s, opacity 0.5s linear;
                
                .text {
                    position: relative;
                    top: 50%;
                }
            }
            
            &:hover .square {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.imgBack {
    position: fixed;
    z-index: -1;
    width: 100%;
    top: 0;
    transform: translateY(-25%);
    opacity: 20%;
}

@media (max-width: 700px) {
    .imgBack {
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        transform: translateY(0%);
        opacity: 20%;
    }
    
    .refs {
        .eduRef, .traineauRef {
            .square .text {
                font-size: small;
            }
        }
    }
}