.mentions {
    display: flex;
    flex-direction: column;
    margin: 25vh 5vh 25vh;
    text-align: left;

    .cookies {
        margin-left: 1rem;
    }

    p {
        color: $white;
        
        a {
            text-decoration: none;
            color: rgb(211, 169, 63);
        }
    }
}