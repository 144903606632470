footer {
    padding: 1rem 0;
    text-align: center;
    font-size: 0.8rem;
    color: $white;
    bottom: 0;
    width: 100%;
    position: fixed;
    background-color: rgba(#000000, 0.6);
    
    .footer-link {
        color: rgb(211, 169, 63);
        margin-left: 5px;
        text-decoration: none;
    }
    
    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding: 0.5rem 0;

        .footer-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 1rem;
        }

        .footer-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 1rem;
        }

        .footer-text {
            font-size: 2rem;
        }
    }
}

@media (max-width: 700px) {
    footer {
        flex-direction: column;

        .footer-container {
            .footer-text {
                font-size: 0.8rem;
            }
        }
    }
}