.left-header{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 150;
    
    img {
        width: 20rem;
        height: 20rem;
    }
}

.website-title {
    font-weight: bolder;
    font-size: 3rem;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    color: $white;
    
    p { 
        margin-top: 7rem;
    }
}

.backTop {
    position: fixed;
    bottom: 10vh;
    right: 5vh;
    background-color: rgb(180, 145, 55);
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 40;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        background-color: rgb(240, 192, 60);
        cursor: pointer;
        transform: scale(1.02);
    }

    svg {
        width: 2rem;
        height: 2rem;
    }
}

.navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 33, 33, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    transform: translateX(200%);
    &.is-open {
        transform: translateX(0);
    }
    
    ul {
        text-align: center;
        list-style-type: none;
        width: 80%;
        
        a {
            color: rgb(200, 200, 200);
            text-decoration: none;
            padding: 0.5rem;
            border-radius: 0.5rem;
            
            &.isActive {
                background-color: rgba(18, 18, 18, 0.3);
                border-radius: 0.5rem;
            }
            
            li {
                display: inline-block;
                width: 100%;
                padding: 0.5rem;
                text-align: center;
                font-size: 1.2rem;
                font-weight: bold;
                color: $white;
            }
            
            &:hover {
                color: rgb(200, 200, 200);
                transform: scale(1.1);
                background-color: rgba(18, 18, 18, 0.3);
            }
        }
    }
}

@media (max-width: 700px) {
    .left-header{
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 150;
        display: flex;
        
        img {
            width: 3rem;
            height: 3rem;
        }
    }
    
    .website-title {
        text-decoration: none;
        color: $white;
        font-size: 1rem;
        
        p { 
            margin-top: 2rem;
        }
    }
    
    .backTop {
        width: 2rem;
        height: 2rem;

        svg {
            width: 1rem;
            height: 1rem;
        }
    }
}