.tarifsTitle {
    text-align: center;
    margin-top: 10vh;
}

.infosEduc {
    background-color: rgb(46, 46, 46);
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    margin: 20vh 5vh 0;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    
    h1 {
        text-align: center;
    }
    
    .imgEducTonPoilu {
        width: 100px;
        margin: 1rem 5vh 0;
        align-self: center;
    }
    
    .presentation {
        margin: 1rem 5vh 0;
    }
}

.tarifs {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 10vh 1vh 25vh;
    
    .imgBack {
        position: fixed;
        z-index: -1;
        width: 100%;
        top: 0;
        transform: translateY(-10%);
    }
    
    div {
        .tarif {
            background-color: rgb(46, 46, 46);
            min-height: 9rem;
            border-radius: 10px;
            padding-top: 10px;
            margin: 3rem;
            box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
            transition: transform 0.2s ease-in-out;
            
            &:hover {
                transform: scale(1.01);
            }
            
            .tarifTitle {
                text-align: center;
                min-height: 3rem;
                font-weight: bold;
                margin: 0 10px 0;
            }
            
            .tarifPrice {
                text-align: center;
                background-color: $black;
                min-height: 3rem;
            }
            
            .tarifDesc {
                text-align: center;
                min-height: 3rem;
                word-wrap: break-word;
                font-size: larger;
                margin: 0 10px 0;
            }
            
            a {
                text-decoration: none;
                color: $white;
                font-weight: bold;
                font-size: larger;
                
                .goContact {
                    text-align: center;
                    height: 3rem;
                    background-color: rgb(180, 145, 55);
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    
                    &:hover {
                        background-color: rgb(240, 192, 60);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .tarifs {
        .imgBack {
            transform: translateY(0%);
        }
    }
}