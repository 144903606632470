.toasts {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1000;
    
    .toast {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        position: relative;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        padding: 1rem;
        margin-bottom: 1rem;
        transition: all 0.3s ease-in-out;
        
        p {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }
        
        .close-toast {
            position: absolute;
            top: 1rem;
            right: 1rem;
            font-size: 1.5rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background: transparent;
            border: none;
            outline: none;
            
            &:hover {
                color: $red;
            }
        }
        
        .toast-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background: rgb(211, 169, 63);
            transition: all 0.3s ease-in-out;
        }
    }
}

.contactForm {
    margin-top:calc(25vh - 7rem);
    
    div, h1, h4 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 15px;
    }
    
    input {
        width: calc(30% - 4px);
        color: #fff;
        background-color: rgba(0, 0, 0, 0.600);
        border: none;
        border-radius: 10px;
        margin: 3px;
        height: 30px;
        padding: 10px;
        
        &::placeholder {
            color: rgb(192, 192, 192);
        }
        
        &:-webkit-autofill {
            outline: none;
            background-color: rgba(0, 0, 0, 0.400);
            -webkit-text-fill-color: #000;
        }
    }
    
    textarea {
        padding: 10px;
        width: 90%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.600);
        border: none;
        border-radius: 10px;
        margin-top: 3px;
        height: 150px;
        resize: none;
        
        &::placeholder {
            color: rgb(192, 192, 192);
        }
    }
    
    textarea:focus,
    input:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.400);
    }
    
    button {
        display: block;
        margin-top: 3px;
        width: 90%;
        color: #fff;
        background-color: rgb(180, 145, 55);
        border: none;
        border-radius: 10px;
        height: 30px;
        margin-bottom: 15px;
        
        &:hover {
            background-color: rgb(240, 192, 60);
            cursor: pointer;
        }
    }
}

@media (max-width: 700px) {
    .contactForm {
        margin-top:calc(25vh - 2rem);
        
        .inputs {
            flex-direction: column;
            
            input {
                width: 90%;
            }
        }
    }    
}