.links {
    display: flex;
    justify-content: center;
    gap: calc(25% / 2);
    margin: 20vh 5vh 25vh;

    .contactBack {
        position: fixed;
        z-index: -1;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 20%;
    }

    span {
        display: flex;
        align-items: center;
        flex-direction: column;

        svg {
            fill: rgb(180, 145, 55);
            width: 6rem;
            height: 6rem;
        }

        .facebook {
            fill:rgb(35, 149, 255);
        }

        h2 {
            text-transform: uppercase;
            margin: 1rem;
            color: rgb(35, 149, 255);
        }

        p {
            color: $white;
            text-align: center;

            a {
                color: rgb(211, 169, 63);
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 700px) {
    .links {
        flex-direction: column;
        gap: 3rem;
    }
}