.contactPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 33, 33, 0.9);
    top: 0;
    left: 0;
    z-index: 50;
    transition: all 0.3s ease-in-out;
    transform: translateY(0);
    display: flex;
    flex-direction: column;

    &.hidden {
        transform: translateY(100%);
    }

    .cancelContact {
        background-color: rgb(180, 63, 55);

        &:hover {
            background-color: rgb(240, 63, 60);
        }
    }

    button {
        display: block;
        margin-top: 3px;
        width: 80%;
        color: #fff;
        border: none;
        border-radius: 10px;
        height: 30px;
        margin-bottom: 15px;
        align-self: center;

        &:hover {
            cursor: pointer;
        }
    }
}

.dogsList {
    margin: 20vh 0 25vh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    .infosParrai {
        text-align: center;
        margin: 0 1rem 0;
    }

    .dogBlock {
        margin: 3rem;
        display: flex;
        width: 80%;
        min-height: 20rem;
        background-color: rgb(46, 46, 46);
        box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
        border-radius: 20px;
        align-items: center;
        flex-direction: column;
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }

        .infos {
            display: flex;
            align-items: center;
            min-height: 20rem;

            .dogImage {
                margin: 2rem;
                width: 20%;
                height: 20%;
            }
    
            .dogText {
                margin: 2rem;
            }
        }

        .goContact {
            text-align: center;
            height: 3rem;
            background-color: rgb(180, 145, 55);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 100%;
            text-decoration: none;
            color: $white;
            font-weight: bold;
            font-size: larger;
            
            &:hover {
                background-color: rgb(240, 192, 60);
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 700px) {
    .dogsList {
        .dogBlock {
            .infos {
                flex-direction: column;

                .dogImage {
                    width: 50%;
                    height: 50%;
                }
            }
        }
    }
}