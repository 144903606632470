@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat/static/Montserrat-Light.ttf");
}

$montserrat: "Montserrat", sans-serif;
$black: rgb(33, 33, 33);
$white: rgb(243, 243, 243);
$red: rgb(175, 0, 0);

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: $black;
    color: $white;
    font-family: $montserrat;
}

button {
    outline: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgb(24, 24, 24);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgb(211, 169, 63);
    border-radius: 5px;
    
    &:hover {
        background: rgb(148, 118, 44);
    }
}

@media (max-width: 700px) {
    ::-webkit-scrollbar {
        width: 5px;
    }
}